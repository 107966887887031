import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'

const AboutPage = () => {
    return (
        <main>
            <title>About Me</title>
            <Layout pageTitle="About Me!">
                <p>Hi, I'm Eric! I am a QA Professional with a passion for delivering quality software. </p>
            </Layout>
        </main>
    )
}

export const Head = () => <Seo title="About" />

export default AboutPage